import { fetchNoResultFormDataService, fetchNoResultService, HttpType } from "./Service"

/** Приложить документ к заявке в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function adddoc(idOrder: number, formData: FormData) {
    await fetchNoResultFormDataService(`Doc?idOrder=${idOrder}`, HttpType.post, formData)
}

/** Приложить несколько документов к заявке в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function addlistdoc(idOrder: number, formData: FormData) {
    await fetchNoResultFormDataService(`Doc/listdoc?idOrder=${idOrder}`, HttpType.post, formData)
}

/** Приложить ПКО к заказу в личном кабинете мастера ГРС на сайте для мобильного приложения (amount - NULL, если требуется расчитать остаток автоматически) */
export async function addpaymentdoc(idOrder: number, amount: number | null) {
    await fetchNoResultService(`Doc/addpaymentdoc?idOrder=${idOrder}` + (amount !== null ? `&amount=${amount}` : ''), HttpType.post)
}