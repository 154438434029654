import { ErrorService } from "../data/Service";

/** Проверка ответа web-сервиса на ошибку авторизации с редиректом на страницу логина (код 401) */
export function CheckUnauthorizedService(error: any) {
    const errorService = error as ErrorService
    if (errorService.code === 401) {
        window.location.href = '/login';
    }
    else {
        alert(errorService.message)
    }
}