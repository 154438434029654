interface ModelProps {
    children: React.ReactNode
    title: string
}

export function Modal({ children, title }: ModelProps) {
    return (
        <>
            <div className="fixed bg-black/60 top-0 right-0 left-0 bottom-0"></div>
            <p></p>
            <div className="p-5 rounded bg-white fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
                <h1 className="text-2xl text-center mb-2">{title}</h1>
                {children}
            </div>
        </>
    )
}