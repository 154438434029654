import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { arrived } from "../data/AccountService"
import { adddoc, addlistdoc, addpaymentdoc } from "../data/DocService"
import { taskcomplete } from "../data/TaskService"
import { ITaskModel } from "../model/TaskModel"
import { CheckUnauthorizedService } from "../page/Page"
import { CompleteTaskComponent } from "./CompleteTaskComponent"
import { Modal } from "./Modal"
import { UploadPhotoComponent } from "./UploadPhotoComponent"

interface TaskComponentProps {
    task: ITaskModel
}

export function TaskComponent({ task }: TaskComponentProps) {
    const [sendPhotoResult, setSendPhotoResult] = useState('Вложить фото')
    const [completeResult, setCompleteResult] = useState('Заявка выполнена')
    const [arrivedSuccessful, setArrivedSuccessful] = useState(task.arrived)
    const [completeSuccessful, setCompleteSuccessful] = useState(false)
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()

    var imageSrc = 'BO_New.png'
    var colorText = 'text-gray-400'
    var statusText = 'Новая'

    switch (task.status) {
        case 0:
            imageSrc = 'BO_Warning.png'
            colorText = 'text-red-500'
            statusText = 'Просрочена'
            break;
        case 1:
            // тут уже проставлены параметры
            break;
        case 2:
            imageSrc = 'BO_Note.png'
            colorText = 'text-green-500'
            statusText = 'Согласована'
            break;
        case 3:
            imageSrc = 'BO_Department.png'
            // цвет уже проставлен
            statusText = 'Назначен исполнитель'
            break;
    }

    async function arrivedHandler() {
        navigator.geolocation.getCurrentPosition(async (geo) => {
            var result = true
            await arrived(task.idGrsJournal, `${geo.coords.latitude},${geo.coords.longitude}`)
                .catch(data => {
                    CheckUnauthorizedService(data)
                    result = false
                })
            if (result) {
                setArrivedSuccessful(true)
            }
        })
    }

    async function complete() {
        var result = true
        await taskcomplete(task.idGrsJournal, '')
            .catch(data => {
                CheckUnauthorizedService(data)
                result = false
            })
        if (result) {
            setCompleteSuccessful(true)
            setCompleteResult(`Выполнено (${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()})`)
        } else {
            setCompleteResult('Заявка выполнена (Произошла ошибка)')
        }
    }

    async function sendPhoto(formData: FormData) {
        var result = true
        await addlistdoc(task.idOrder, formData)
            .catch(data => {
                CheckUnauthorizedService(data)
                result = false
            })
        if (result) {
            setSendPhotoResult(`Вложить фото (${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()})`)
        }
        setModal(false)
    }

    function openTask() {
        navigate('/opentask', { state: { taskModel: task, isNew: false, isTake: false } })
    }

    return (
        <div className="border border-gray-500 mb-2 px-5 py-3">
            <div className="flow-root">
                <div className="float-left flex items-center">
                    <p className="text-xl">{task.orderName}</p>
                    <p className="text-xs text-gray-400 ml-2">от {task.planDate.toLocaleDateString()}</p>
                </div>
                <img className="float-right h-6 w-6" alt="status" src={imageSrc} />
            </div>
            <p className={`text-xs ${colorText} mb-3`}>{statusText}</p>
            <button className="w-full border border-gray-400 text-gray-600 py-2 mb-3" onClick={openTask}>Перейти в заявку</button>
            <div className="flex mb-2">
                <p className="text-sm font-bold">Адрес:</p>
                <a className="text-xs text-blue-700 underline ml-2" target="_blank" rel="noreferrer" href={`https://yandex.ru/maps/?text=${task.address}`}>{task.address}</a>
            </div>
            <div className="flex items-center mb-2">
                <p className="text-sm font-bold">Телефон:</p>
                <a className="text-sm text-orange-400 underline ml-2" href={`tel:${task.phone}`}>{task.phone}</a>
            </div>
            <p className="text-sm font-bold">Комментарий диспетчера:</p>
            <p className="text-xs text-gray-400">{task.comment}</p>
            <p className="text-sm font-bold">Комментарий к заявке:</p>
            <p className="text-xs text-gray-400 mb-5">{task.installInfo}</p>
            {!arrivedSuccessful && <button className="w-full border bg-gray-200 py-2 mb-3" onClick={arrivedHandler}>Прибыл</button>}
            {arrivedSuccessful && <div>
                <button className="w-full border bg-red-500 text-white py-2 mb-3" onClick={() => setModal(true)}>{sendPhotoResult}</button>
                <button className="w-full border bg-green-600 text-white py-2" disabled={completeSuccessful} onClick={complete}>{completeResult}</button>
            </div>}
            {modal && <Modal title='Вложить фото'>
                <UploadPhotoComponent onAction={sendPhoto} onClose={() => setModal(false)} />
            </Modal>}
        </div>
    )
}