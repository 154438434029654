import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './page/LoginPage';
import { NewTaskPage } from './page/NewTaskPage';
import { OpenFurniturePage } from './page/OpenFurniturePage';
import { OpenOrderPage } from './page/OpenOrderPage';
import { OpenTaskPage } from './page/OpenTaskPage';
import { TaskPage } from './page/TaskPage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='/login' element={ <LoginPage /> } />
      <Route path='/task' element={<TaskPage />} />
      <Route path='/newtask' element={<NewTaskPage />} />
      <Route path='/opentask' element={<OpenTaskPage />} />
      <Route path='/openorder' element={<OpenOrderPage />} />
      <Route path='/openfurniture' element={<OpenFurniturePage />} />
    </Routes>
  )
}

export default App;
