import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signin } from "../data/AccountService";
import { Settings } from "../Settings";

export function LoginPage() {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [showLoading, setShowLoading] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const navigate = useNavigate()

    async function enterHandler() {
        setShowLoading(true)
        if (login === null || login === '' || password === null || password === '') {
            setShowWarning(true)
        } else {
            if (await signin(login, password).catch(() => setShowWarning(true))) {
                navigate('/task')
            } else {
                setShowWarning(true)
            }
        }
        setShowLoading(false)
    }

    return (
        <div className="h-screen flex flex-col items-center justify-center ml-3 mr-3">
            <div className="flex items-center mb-4">
                <img alt="logo" src='favicon.png' width="24" height="24" />
                <p className="text-2xl font-bold ml-1">Forte ГРС</p>
            </div>
            <div>
                <input type="text" value={login} placeholder="Логин" className="w-full mr-3 py-5 px-4 h-2 border border-gray-300 rounded mb-3"
                    onChange={e => setLogin(e.target.value)} />
                <input type="password" value={password} placeholder="Пароль" className="w-full mr-3 py-5 px-4 h-2 border border-gray-300 rounded mb-3"
                    onChange={e => setPassword(e.target.value)} />
                <button className="bg-blue-500 text-white w-full py-2 mt-3 mb-1" disabled={showLoading} onClick={enterHandler}>
                    {showLoading ? 'Подождите ...' : 'Войти'}
                </button>
            </div>
            <p className={showWarning ? 'text-red-500' : 'text-white'}>не верный логин или пароль</p>
            <p className="text-sm absolute bottom-0 py-4">Версия приложения: {Settings.version}</p>
        </div>
    )
}