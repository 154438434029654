import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NewTaskComponent } from "../component/NewTaskComponent";
import { newtask } from "../data/TaskService";
import { ITaskModel } from "../model/TaskModel";
import { CheckUnauthorizedService } from "./Page";

export function NewTaskPage() {
    const [arrayNewTaskModel, setNewArrayTaskModel] = useState<ITaskModel[]>([])
    const [showLoading, setShowLoading] = useState(true)

    async function loadNewTask() {
        setNewArrayTaskModel([])
        setShowLoading(true)
        const result = await newtask().catch(data => CheckUnauthorizedService(data)) as ITaskModel[]
        setNewArrayTaskModel(result)
        setShowLoading(false)
    }

    useEffect(() => {
        loadNewTask()
    }, [])

    return (
        <div className="flex flex-col items-center justify-center">
            <Link className='text-2xl text-red-800 underline mt-4 mb-3' to="/task">Мои заявки</Link>
            <p className="text-xl mb-3">Новые заявки ГРС</p>
            <div className="w-full px-2">
                <button className="w-full border border-gray-400 text-gray-600 py-2 mb-5" onClick={loadNewTask}>Обновить список</button>
            </div>
            {showLoading && <div><br /><br /><br /><p className="text-2xl font-bold text-center mt-11">Подождите идет загрузка ...</p></div>}
            <div className="w-full px-2">
                {arrayNewTaskModel.map(taskModel => <NewTaskComponent task={taskModel} key={taskModel.idGrsJournal} />)}
            </div>
        </div>
    )
}