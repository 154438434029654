import { ILoginModel } from "../model/LoginModel"
import { fetchNoResultService, fetchService, HttpType, Service } from "./Service"

/** Авторизация мастера ГРС в личном кабинете на сайте для мобильного приложения */
export async function signin(login: string, password: string): Promise<boolean> {
    var loginModel = await fetchService<ILoginModel>('Account', HttpType.post, `{"login": "${login.trim()}","password": "${password}"}`)
    if (loginModel.token === null || loginModel.token === '') {
        throw new Error('Incorrect login or password');
    }
    Service.tokenjwt = loginModel.token
    Service.account = loginModel.account
    return true
}

/** Установка статуса Прибыл на объект в заявке в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function arrived(idGrsJournal: number, geocode: string) {
    await fetchNoResultService(`Account/arrived?idGrsJournal=${idGrsJournal}&geocode=${geocode}`, HttpType.post)
}