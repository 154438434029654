import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { picture } from "../data/OrderService"
import { IOrderItemModel } from "../model/OrderItemModel"
import { CheckUnauthorizedService } from "../page/Page"

interface OrderItemComponentProps {
    orderItem: IOrderItemModel
}

export function OrderItemComponent({ orderItem }: OrderItemComponentProps) {
    const [imageSrc, setImageSrc] = useState('BO_New.png')
    const [isDownloading, setIsDownloading] = useState(true)
    const navigate = useNavigate()

    function openFurniture() {
        navigate('/openfurniture', { state: { idOrderItem: orderItem.idOrderItem, orderItemName: orderItem.name } })
    }

    async function loadPictureWindow() {
        if (orderItem.isWindow) {
            const result = await picture(orderItem.idOrderItem).catch(data => CheckUnauthorizedService(data))
            if (result !== undefined) {
                setImageSrc('data:image/jpeg;base64,' + result)
            }
            setIsDownloading(false)
        }
    }

    useEffect(() => {
        loadPictureWindow()
    }, [])

    return (
        <div className="border p-2 mb-2">
            {orderItem.isWindow && isDownloading && <p className="font-bold mt-11 mb-11">загрузка фото ...</p>}
            {orderItem.isWindow && !isDownloading && <img className="h-auto" alt="window" src={imageSrc} />}
            <p className="text-lg mb-2">{orderItem.position} : {orderItem.name}</p>
            <p className="text-gray-400 mb-2">{orderItem.size} {orderItem.color} {orderItem.quantity > 0 ? `${orderItem.quantity} шт.` : ''}</p>
            <div className="flex mb-2">
                <p className="text-xs font-bold">Подразделение:</p>
                <p className="text-xs text-gray-400 ml-2">{orderItem.department}</p>
            </div>
            {orderItem.shipDate !== null && <p className="text-gray-400">{orderItem.shipDate.toLocaleDateString()}</p>}
            {orderItem.isWindow && orderItem.hasFurniture && <p className='text-lg text-red-800 underline' onClick={openFurniture}>Состав фурнитуры</p>}
        </div>
    )
}