import { IFurnitureModel } from "../model/FurnitureModel";
import { IOrderModel } from "../model/OrderModel";
import { fetchScalarResultService, fetchService, HttpType } from "./Service";

/** Информация о спецификации фурнитуры в позиции заказа в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function furninfo(idOrderItem: number): Promise<IFurnitureModel[]> {
    return await fetchService<IFurnitureModel[]>(`Order/furninfo?idOrderItem=${idOrderItem}`, HttpType.get)
}

/** Информация о заказе в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function order(idOrder: number): Promise<IOrderModel> {
    const orderModel = await fetchService<IOrderModel>(`Order?idOrder=${idOrder}`, HttpType.get)
    orderModel.shipDate = new Date(orderModel.shipDate)
    if (orderModel.installDate !== null) {
        orderModel.installDate = new Date(orderModel.installDate)
    }
    orderModel.listOrderItemModel.forEach(orderItemModel => {
        if (orderItemModel.shipDate !== null) {
            orderItemModel.shipDate = new Date(orderItemModel.shipDate)
        }
    })
    return orderModel
}

/** Картинка окна из WinDraw */
export async function picture(idOrderItem: number): Promise<string> {
    return await fetchScalarResultService<string>(`Order/picture?idOrderItem=${idOrderItem}`, HttpType.get)
}