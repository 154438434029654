import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { FurnitureComponent } from "../component/FurnitureComponent"
import { furninfo } from "../data/OrderService"
import { IFurnitureModel } from "../model/FurnitureModel"
import { CheckUnauthorizedService } from "./Page"

export function OpenFurniturePage() {
    const location = useLocation()
    const idOrderItem: number = location.state.idOrderItem
    const orderItemName: number = location.state.orderItemName
    const [arrayFurnitureModel, setArrayFurnitureModel] = useState<IFurnitureModel[]>([])
    const [showLoading, setShowLoading] = useState(true)

    async function loadFurniture() {
        const result = await furninfo(idOrderItem).catch(data => CheckUnauthorizedService(data)) as IFurnitureModel[]
        setArrayFurnitureModel(result)
        setShowLoading(false)
    }

    useEffect(() => {
        loadFurniture()
    }, [])

    return (
        <div className="p-2">
            <p className="text-2xl">{orderItemName}</p>
            <p className="text-gray-400 mb-2">Состав фурнитуры:</p>
            {showLoading && <div><br /><br /><br /><p className="text-2xl font-bold text-center mt-11">Подождите идет загрузка ...</p></div>}
            <div className="w-full">
                {arrayFurnitureModel?.map((furnitureModel, i) => <FurnitureComponent furniture={furnitureModel} key={i} />)}
            </div>
        </div>
    )
}