import { FormEvent, useState } from "react"

interface UploadPhotoComponentProps {
    onAction: (formData: FormData) => Promise<void>
    onClose: () => void
}

export function UploadPhotoComponent({ onAction, onClose }: UploadPhotoComponentProps) {
    const [titleButton, setTitleButton] = useState('Ok')
    const [showLoading, setShowLoading] = useState(false)

    function submit(formEvent: FormEvent<HTMLFormElement>) {
        formEvent.preventDefault()
        setShowLoading(true)
        setTitleButton('Подождите ...')
        onAction(new FormData(formEvent.target as HTMLFormElement))
    }

    return(
        <div>
            <form encType="multipart/form-data" onSubmit={e => { submit(e) }}>
                <br />
                <input required type="file" name="imageUpload" disabled={showLoading} accept="image/*" multiple />
                <br />
                <br />
                <br />
                <input className="w-full border bg-red-500 text-white py-2 mb-4" disabled={showLoading} type="submit" value={titleButton} />
            </form>
            <button className="w-full border text-gray-500 py-2 mb-3" disabled={showLoading} onClick={onClose}>Отмена</button>
        </div>
    )
}