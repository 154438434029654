import { IAccountModel } from "../model/AccountModel";
import { Settings } from "../Settings";

/** Данные для обращения к web-сервисам */
interface IService {
    urlbase: string
    tokenjwt: string
    account?: IAccountModel
}

export enum HttpType {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete'
}

export class ErrorService extends Error {
    code: number
    constructor(message: string, code: number) {
        super(message)
        this.code = code
    }
}

/** Данные для обращения к web-сервисам */
export const Service: IService = {
    urlbase: Settings.urlbase,
    tokenjwt: '',
    account: undefined
}

/** Проверка ответа web-сервиса (throw error) */
function CheckErrorService(response: Response) {
    if (!response.ok) {
        throw new ErrorService(`Error: ${response.status} ${response.statusText}`, response.status)
    }
}

/** Создание объекта параметров запроса */
function CreateRequestInit(type: string, body?: string): RequestInit {
    var requestInit: RequestInit = {}
    requestInit.method = type
    var arrayHeader: HeadersInit = new Headers()
    arrayHeader.set('Authorization', 'Bearer ' + Service.tokenjwt)
    if (body !== null && body !== '') {
        arrayHeader.set('Content-Type', 'application/json')
        requestInit.body = body
    }
    requestInit.headers = arrayHeader
    return requestInit
}

/** Выполнение запроса к web-сервису на сервере (авторизированный запрос без результата) */
export async function fetchNoResultService(url: string, type: string, body?: string) {
    var requestInit: RequestInit = CreateRequestInit(type, body)
    await fetch(Service.urlbase + url, requestInit)
        .then(response => {
            CheckErrorService(response)
        })
}

/** Выполнение запроса к web-сервису на сервере с данными формы (авторизированный запрос без результата) */
export async function fetchNoResultFormDataService(url: string, type: string, formData: FormData) {
    await fetch(Service.urlbase + url, {
            method: type,
            headers: { Authorization: 'Bearer ' + Service.tokenjwt },
            body: formData
        })
        .then(response => {
            CheckErrorService(response)
        })
}

/** Выполнение запроса к web-сервису на сервере (авторизированный запрос с результатом в виде простого типа) */
export async function fetchScalarResultService<T>(url: string, type: string, body?: string): Promise<T> {
    var result: T = {} as T
    var requestInit: RequestInit = CreateRequestInit(type, body)
    await fetch(Service.urlbase + url, requestInit)
        .then(response => {
            CheckErrorService(response)
            return response.text() as Promise<T>
        })
        .then(data => {
            result = data
        })
    return result
}

/** Выполнение запроса к web-сервису на сервере (авторизированный запрос с результатом) */
export async function fetchService<T>(url: string, type: string, body?: string): Promise<T> {
    var result: T = {} as T
    var requestInit: RequestInit = CreateRequestInit(type, body)
    await fetch(Service.urlbase + url, requestInit)
        .then(response => {
            CheckErrorService(response)
            return response.json() as Promise<T>
        })
        .then(data => {
            result = data
        })
    return result
}