import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TaskComponent } from "../component/TaskComponent";
import { Service } from "../data/Service";
import { task } from "../data/TaskService";
import { ITaskModel } from "../model/TaskModel";
import { CheckUnauthorizedService } from "./Page";

export function TaskPage() {
    const [arrayTaskModel, setArrayTaskModel] = useState<ITaskModel[]>([])
    const [showLoading, setShowLoading] = useState(true)
    
    async function loadTask() {
        setArrayTaskModel([])
        setShowLoading(true)
        const result = await task().catch(data => CheckUnauthorizedService(data)) as ITaskModel[]
        setArrayTaskModel(result)
        setShowLoading(false)
    }

    useEffect(() => {
        loadTask()
    }, [])

    return (
        <div className="flex flex-col items-center justify-center">
            <Link className='text-2xl text-red-800 underline mt-4 mb-3' to="/newtask">Нераспределенные заявки</Link>
            <p className="text-xl mb-3">Мои заявки ({Service.account?.name})</p>
            <div className="w-full px-2">
                <button className="w-full border border-gray-400 text-gray-600 py-2 mb-5" onClick={loadTask}>Обновить список</button>
            </div>
            {showLoading && <div><br /><br /><br /><p className="text-2xl font-bold text-center mt-11">Подождите идет загрузка ...</p></div>}
            <div className="w-full px-2">
                {arrayTaskModel.map(taskModel => <TaskComponent task={taskModel} key={taskModel.idGrsJournal} />)}
            </div>
        </div>
    )
}