import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { taketask } from "../data/TaskService"
import { ITaskModel } from "../model/TaskModel"
import { CheckUnauthorizedService } from "../page/Page"

interface TaskComponentProps {
    task: ITaskModel
}

export function NewTaskComponent({ task }: TaskComponentProps) {
    const [takeResult, setTakeResult] = useState('Забрать заявку')
    const [takeSuccessful, setTakeSuccessful] = useState(false)
    const navigate = useNavigate()

    async function take() {
        var result = true
        await taketask(task.idGrsJournal)
            .catch(data => {
                CheckUnauthorizedService(data)
                alert('Произошла ошибка.')
                result = false
            })
        if (result) {
            setTakeSuccessful(true)
            setTakeResult(`Забрано (${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()})`)
        } else {
            setTakeResult(takeResult + ' (Произошла ошибка)')
        }
    }

    function openTask() {
        navigate('/opentask', { state: { taskModel: task, isNew: true, isTake: takeSuccessful } })
    }

    return (
        <div className="border border-gray-500 mb-2 px-5 py-3">
            <div className="relative">
                <img className="absolute top-0 right-0 h-6 w-6" alt="status" src='BO_New.png' />
            </div>
            <div className="flex items-center">
                <p className="text-xl">{task.orderName}</p>
                <p className="text-xs text-gray-400 ml-2">от {task.planDate.toLocaleDateString()}</p>
            </div>
            <p className="text-xs text-gray-400 mb-3">Новая</p>
            <button className="w-full border border-gray-400 text-gray-600 py-2 mb-3" onClick={openTask}>Перейти в заявку</button>
            <div className="flex mb-2">
                <p className="text-sm font-bold">Адрес:</p>
                <a className="text-xs text-blue-700 underline ml-2" target="_blank" rel="noreferrer" href={`https://yandex.ru/maps/?text=${task.address}`}>{task.address}</a>
            </div>
            <div className="flex items-center mb-2">
                <p className="text-sm font-bold">Телефон:</p>
                <a className="text-sm text-orange-400 underline ml-2" href={`tel:${task.phone}`}>{task.phone}</a>
            </div>
            <div className="flex items-center mb-2">
                <p className="text-sm font-bold">Контрагент:</p>
                <p className="text-xs text-gray-400 ml-2">{task.customerName}</p>
            </div>
            <p className="text-sm font-bold">Комментарий диспетчера:</p>
            <p className="text-xs text-gray-400">{task.comment}</p>
            <p className="text-sm font-bold">Комментарий к заявке:</p>
            <p className="text-xs text-gray-400 mb-5">{task.installInfo}</p>
            <button className="w-full border bg-green-600 text-white py-2" disabled={takeSuccessful} onClick={take}>{takeResult}</button>
        </div>
    )
}