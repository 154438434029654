import { ITaskItemModel } from "../model/TaskItemModel"
import { ITaskModel } from "../model/TaskModel"
import { fetchNoResultService, fetchService, HttpType } from "./Service"

/** Не распределенные заявки в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function newtask(): Promise<ITaskModel[]> {
    const arrayTaskModel = await fetchService<ITaskModel[]>('Task/newtask', HttpType.get)
    arrayTaskModel.forEach(taskModel => { taskModel.planDate = new Date(taskModel.planDate) })
    return arrayTaskModel
}

/** Распределенные заявки мастера ГРС в личном кабинете на сайте для мобильного приложения */
export async function task(): Promise<ITaskModel[]> {
    const arrayTaskModel = await fetchService<ITaskModel[]>('Task', HttpType.get)
    arrayTaskModel.forEach(taskModel => { taskModel.planDate = new Date(taskModel.planDate) })
    return arrayTaskModel
}

/** Информация о работах в заявке в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function taskinfo(idGrsJournal: number): Promise<ITaskItemModel[]> {
    return await fetchService<ITaskItemModel[]>(`Task/taskinfo?idGrsJournal=${idGrsJournal}`, HttpType.get)
}

/** Забрать не распределенную заявку в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function taketask(idGrsJournal: number) {
    return await fetchNoResultService(`Task/taketask?idGrsJournal=${idGrsJournal}`, HttpType.post)
}

/** Установить статус "Выполнена" на заявку в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function taskcomplete(idGrsJournal: number, comment: string) {
    return await fetchNoResultService(`Task/taskcomplete?idGrsJournal=${idGrsJournal}&comment=${comment}`, HttpType.post)
}

/** Согласовать заявку в личном кабинете мастера ГРС на сайте для мобильного приложения */
export async function taskisagreed(idGrsJournal: number, planDate: Date) {
    return await fetchNoResultService(`Task/taskisagreed?idGrsJournal=${idGrsJournal}&planDate=${planDate.toISOString()}`, HttpType.post)
}