import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { OrderItemComponent } from "../component/OrderItemComponent"
import { order } from "../data/OrderService"
import { IOrderModel } from "../model/OrderModel"
import { CheckUnauthorizedService } from "./Page"

export function OpenOrderPage() {
    const location = useLocation()
    const idOrder: number = location.state.idOrder
    const [orderModel, setOrderModel] = useState<IOrderModel>({} as IOrderModel)
    const [colorText, setColorText] = useState('text-gray-400')
    const [statusText, setStatusText] = useState('На контроле в ТО')
    const [shipDateText, setShipDateText] = useState('')
    const [installDateText, setInstallDateText] = useState('')
    const [showLoading, setShowLoading] = useState(true)

    async function loadOrder() {
        const result = await order(idOrder).catch(data => CheckUnauthorizedService(data)) as IOrderModel
        switch (result.status) {
            case 25:
                setStatusText('Прорабатывается в ТО')
                break;
            case 26:
                setStatusText('Отправлен в работу')
                break;
            case 27:
                setStatusText('Доставлен')
                break;
            case 28:
                setStatusText('Контроль в ОС')
                break;
            case 29:
                setStatusText('В производстве')
                break;
            case 30:
                setStatusText('На КС')
                break;
            case 31:
                setStatusText('Отгружен')
                break;
            case 32:
                setStatusText('Установлен')
                break;
            case 33:
                setColorText('text-green-500')
                setStatusText('Закрыт')
                break;
            case 34:
                setStatusText('Возвращен из ИСП')
                break;
        }
        setShipDateText(result.shipDate.toLocaleDateString())
        if (result.installDate !== null) {
            setInstallDateText(result.installDate.toLocaleDateString())
        }
        setOrderModel(result)
        setShowLoading(false)
    }

    useEffect(() => {
        loadOrder()
    }, [])

    return (
        <div className="p-3">
            <p className="text-2xl">Заказ № {orderModel?.name}</p>
            <p className={`text-lg ${colorText} mb-1`}>{statusText}</p>
            <p className="text-lg text-gray-400 mb-1">Дата отгрузки: {shipDateText}</p>
            <p className="text-lg text-gray-400 mb-1">Дата монтажа: {installDateText}</p>
            {showLoading && <div><br /><br /><br /><p className="text-2xl font-bold text-center mt-11">Подождите идет загрузка ...</p></div>}
            <div className="w-full">
                {orderModel?.listOrderItemModel?.map(orderItemModel => <OrderItemComponent orderItem={orderItemModel} key={orderItemModel.position} />)}
            </div>
        </div>
    )
}