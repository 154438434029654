import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "../component/Modal";
import { UploadPhotoComponent } from "../component/UploadPhotoComponent";
import { arrived } from "../data/AccountService";
import { adddoc, addlistdoc, addpaymentdoc } from "../data/DocService";
import { taketask, taskcomplete, taskinfo, taskisagreed } from "../data/TaskService";
import { ITaskModel } from "../model/TaskModel";
import { CheckUnauthorizedService } from "./Page";

import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { ITaskItemModel } from "../model/TaskItemModel";
import { TaskItemComponent } from "../component/TaskItemComponent";
import { CompleteTaskComponent } from "../component/CompleteTaskComponent";

registerLocale('ru', ru)

export function OpenTaskPage() {
    const location = useLocation()
    const task: ITaskModel = location.state.taskModel
    const isNew: boolean = location.state.isNew
    const isTake: boolean = location.state.isTake
    const [completeResult, setCompleteResult] = useState('Заявка выполнена')
    const [arrivedSuccessful, setArrivedSuccessful] = useState(task.arrived)
    const [completeSuccessful, setCompleteSuccessful] = useState(false)
    const [planDate, setPlanDate] = useState(task.planDate)
    const [arrayTaskItemModel, setArrayTaskItemModel] = useState<ITaskItemModel[]>([])
    const [takeResult, setTakeResult] = useState('Забрать заявку')
    const [takeSuccessful, setTakeSuccessful] = useState(location.state.isTake)
    const [showLoading, setShowLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const navigate = useNavigate()

    var amount: number = 0;
    arrayTaskItemModel.forEach(a => amount += (a.price * a.quantity))

    var colorText = 'text-gray-400'
    var statusText = 'Новая'

    switch (task.status) {
        case 0:
            colorText = 'text-red-500'
            statusText = 'Просрочена'
            break;
        case 1:
            // тут уже проставлены параметры
            break;
        case 2:
            colorText = 'text-green-500'
            statusText = 'Согласована'
            break;
        case 3:
            // цвет уже проставлен
            statusText = 'Назначен исполнитель'
            break;
    }

    async function arrivedHandler() {
        navigator.geolocation.getCurrentPosition(async (geo) => {
            var result = true
            await arrived(task.idGrsJournal, `${geo.coords.latitude},${geo.coords.longitude}`)
                .catch(data => {
                    CheckUnauthorizedService(data)
                    alert('Произошла ошибка.')
                    result = false
                })
            if (result) {
                setArrivedSuccessful(true)
            }
        })
    }

    async function complete() {
        var result = true
        await taskcomplete(task.idGrsJournal, '')
            .catch(data => {
                CheckUnauthorizedService(data)
                alert('Произошла ошибка.')
                result = false
            })
        if (result) {
            setCompleteSuccessful(true)
            setCompleteResult(`Выполнено (${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()})`)
        } else {
            setCompleteResult(completeResult + ' (Произошла ошибка)')
        }
    }

    async function sendPhoto(formData: FormData) {
        await addlistdoc(task.idOrderParent, formData)
            .catch(data => {
                CheckUnauthorizedService(data)
                alert('Произошла ошибка.')
            })
        setModal(false)
    }

    async function changePlanDate(date: Date) {
        var result = true
        await taskisagreed(task.idGrsJournal, date)
            .catch(data => {
                CheckUnauthorizedService(data)
                alert('Произошла ошибка.')
                result = false
            })
        if (result) {
            setPlanDate(date)
            task.planDate = date
        }
    }

    async function take() {
        var result = true
        await taketask(task.idGrsJournal)
            .catch(data => {
                CheckUnauthorizedService(data)
                alert('Произошла ошибка.')
                result = false
            })
        if (result) {
            setTakeSuccessful(true)
            setTakeResult(`Забрано (${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()})`)
        } else {
            setTakeResult(takeResult + ' (Произошла ошибка)')
        }
    }

    function openOrderParent() {
        navigate('/openorder', { state: { idOrder: task.idOrderParent } })
    }

    async function loadTaskItem() {
        const result = await taskinfo(task.idGrsJournal).catch(data => CheckUnauthorizedService(data)) as ITaskItemModel[]
        setArrayTaskItemModel(result)
        setShowLoading(false)

        if (isTake) {
            setTakeSuccessful(true)
            setTakeResult('Забрано')
        }
    }

    useEffect(() => {
        loadTaskItem()
    }, [])

    return (
        <div className="p-3">
            <p className="text-xl">Заказ № {task.orderName}</p>
            <p className="text-lg text-gray-400 mb-1">{task.planDate.toLocaleDateString()}</p>
            <p className={`text-lg ${colorText} mb-1`}>{statusText}</p>
            <a className="text-lg text-blue-700 underline" target="_blank" rel="noreferrer" href={`https://yandex.ru/maps/?text=${task.address}`}>{task.address}</a>
            <p />
            <a className="text-lg text-orange-400 underline" href={`tel:${task.phone}`}>{task.phone}</a>
            <p className="text-lg">{task.customerName}</p>
            {task.amount !== null && task.amount > 0 && <p className="text-lg text-gray-400">Сумма заказа {task.amount} руб.</p>}
            <p className="text-lg font-bold">Комментарий диспетчера:</p>
            <p className="text-lg">{task.comment}</p>
            <p className="text-lg font-bold">Комментарий к заявке:</p>
            <p className="text-lg mb-3">{task.installInfo}</p>
            {isNew && <button className="w-full border bg-green-600 text-white py-2 mb-3" disabled={takeSuccessful} onClick={take}>{takeResult}</button>}
            {!isNew && !arrivedSuccessful && <button className="w-full border bg-gray-200 py-2 mb-3" onClick={arrivedHandler}>Прибыл</button>}
            {!isNew && arrivedSuccessful && <div className="mb-2">
                <button className="w-full border bg-red-500 text-white py-2 mb-3" onClick={() => setModal(true)}>Вложить фото</button>
                <button className="w-full border bg-green-600 text-white py-2" disabled={completeSuccessful} onClick={complete}>{completeResult}</button>
            </div>}
            <p className="text-lg">Изменить согласованную дату и время:</p>
            <DatePicker className="w-full text-lg border p-1 mb-2" disabled={isNew || completeSuccessful} selected={planDate} showTimeSelect dateFormat="dd.MM.yyyy HH:mm" locale="ru" onChange={changePlanDate} />
            {showLoading && <div><p className="text-xl font-bold text-center mt-7 mb-10">Подождите идет загрузка ...</p></div>}
            <div className="w-full">
                {arrayTaskItemModel.map((taskItemModel, i) => <TaskItemComponent taskItem={taskItemModel} key={i} />)}
            </div>
            <p className="text-lg mt-1 mb-5">ИТОГО: {amount} руб.</p>
            {task.orderNameParent !== null && <p className='text-lg text-red-800 underline mt-4 mb-3' onClick={openOrderParent}>На основании заказа {task.orderNameParent}</p>}
            {modal && <Modal title='Вложить фото'>
                <UploadPhotoComponent onAction={sendPhoto} onClose={() => setModal(false)} />
            </Modal>}
        </div>
    )
}