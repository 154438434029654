import { IFurnitureModel } from "../model/FurnitureModel"

interface FurnitureComponentProps {
    furniture: IFurnitureModel
}

export function FurnitureComponent({ furniture }: FurnitureComponentProps) {
    return (
        <div className="border p-1 mb-2">
            <div className="flex items-baseline">
                <p className="font-bold">Группа материалов:</p>
                <p className="text-sm text-gray-400 ml-2">{furniture.goodGroup}</p>
            </div>
            <div className="flex items-baseline">
                <p className="font-bold">Артикул:</p>
                <p className="text-sm text-gray-400 ml-2">{furniture.marking}</p>
            </div>
            <div className="flex items-baseline">
                <p className="font-bold">Наименование:</p>
                <p className="text-sm text-gray-400 ml-2">{furniture.name}</p>
            </div>
            <div className="flex items-baseline">
                <p className="font-bold">Количество:</p>
                <p className="text-sm text-gray-400 ml-2">{furniture.quantity} шт.</p>
            </div>
            <div className="flex">
                <p className="text-sm text-gray-400">Часть:</p>
                <p className="text-sm text-gray-400 ml-2">{furniture.modelPart}</p>
            </div>
        </div>
    )
}