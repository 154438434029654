/** Настройки приложения */
interface ISettings {
    urlbase: string
    version: string
}

/** Настройки приложения (в urlbase обязательно в конце /) */
export const Settings: ISettings = {
    urlbase: 'https://fortegrs.forte.su/',
    version: '1.0.0.2 (build 16.06.2023)'
}