import { ITaskItemModel } from "../model/TaskItemModel"

interface TaskItemComponentProps {
    taskItem: ITaskItemModel
}

export function TaskItemComponent({ taskItem }: TaskItemComponentProps) {
    return (
        <div className="bg-gray-50 mb-1">
            <p className="text-lg">{taskItem.name}</p>
            <p className="text-sm">{taskItem.price} руб. - {taskItem.quantity} шт.</p>
            <p className="text-sm text-gray-600 font-bold">итого: {taskItem.price*taskItem.quantity} руб.</p>
        </div>
    )
}